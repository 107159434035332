<template>
  <div>
    <div v-if="hasPendingRequest && wantsRequestChanges" class="alert alert-danger" role="alert">
      There can be only one pending request. Please wait until the request is processed or contact support.
    </div>
    <b-button v-if="!requestingChanges" @click="click" block variant="warning ">Request Changes</b-button>
  </div>
</template>
<script>
export default {
  props: {
    wantsRequestChanges: {
      type: Boolean,
      required: true
    },
    requestingChanges: {
      type: Boolean,
      required: true
    },
    hasPendingRequest: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    click() {
      this.$emit('update', { wantsRequestChanges: true })
    }
  }
}
</script>
