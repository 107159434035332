<template>
  <div class="breakdown-sec pt-2">
    <div class="col-md-10 offset-md-1 px-4">
      <h2 class="sub-head text-center my-3">Cleaning Breakdown</h2>
      <div class="float-parent font-mid-bold pb-4">
        <div class="float-left">{{ event.pretty_requested_cleaning }} Cleaning Charges<custom-price-note :event="event" /></div>
        <div class="float-right blue-text">{{ cleaningCharges }}</div>
      </div>
      <extra-charges
        :event="event"
        ></extra-charges>
      <hwe-details :event="event"></hwe-details>
      <parking-fee :event="event"></parking-fee>
      <cancellation-fee :event="event"></cancellation-fee>
      <hr>
      <spinner :show="priceLoading" :use-modal="false" message="Loading"></spinner>
      <template v-if="!priceLoading">
        <div v-if="hasPromoCode || promoCodeEditMode" class="float-parent font-mid-bold clearfix mb-2">
          <div class="float-left">Promo Code</div>
          <div v-if="!promoCodeEditMode" class="float-right">{{ promoCode }}</div>
          <input v-if="promoCodeEditMode" type="text" v-model="customPromoCode" class="float-right form-control text-right">
        </div>

        <div v-if="priceEditMode" class="row">
          <div class="col-12">
            <small class="form-text text-muted">
              Enter the amount as a number for the adjustment or discount. If the discount is a percentage of the total, use the $/% dropdown. The adjustment can only be positive, the discount negative.
            </small>
          </div>
        </div>
        <adjustment
          :edit-mode="priceEditMode"
          :adjustment="adjustment"
          :applies="adjustmentApplies"
          :type="adjustmentType"
          :description="adjustmentDescription"
          :placeholder="adjustmentDescriptionPlaceholder"
          @save="savePriceChange"
          @update="updateValue($event)"
          ref="adjustment"
          class="float-parent font-mid-bold my-2 clearfix" />

        <discount
          :edit-mode="priceEditMode"
          :discount="discount"
          :amount="discountAmount"
          :applies="discountApplies"
          :type="discountType"
          :description="discountDescription"
          :placeholder="discountDescriptionPlaceholder"
          @save="savePriceChange"
          @update="updateValue($event)"
          ref="discount"
          class="float-parent font-mid-bold my-2 clearfix" />

        <bundled-discount
          :edit-mode="priceEditMode"
          :discount="bundledDiscount"
          :amount="bundledDiscountAmount"
          :type="bundledDiscountType"
          :description="bundledDiscountDescription"
          :placeholder="bundledDiscountDescriptionPlaceholder"
          @save="savePriceChange"
          @update="updateValue($event)"
          ref="bundledDiscount"
          class="float-parent font-mid-bold my-2 clearfix" />

        <visit-reimbursement
          :edit-mode="false"
          :description="reimbursementDescription"
          :reimbursement="reimbursement"
          :charge="true"
          class="float-parent font-mid-bold my-2 clearfix" />

        <visit-bonus
          :edit-mode="false"
          :description="bonusDescription"
          :bonus="bonus"
          :charge="false"
          class="float-parent font-mid-bold my-2 clearfix" />

        <div class="float-parent font-mid-bold clearfix">
          <div class="float-left">Total Amount</div>
          <div class="float-right blue-text">{{totalCleaningCharges}}</div>
        </div>
        <mode-switcher v-if="canChangePromoCode" :edit-mode="promoCodeEditMode" label="Edit Promo Code for this visit" @edit="$emit('update:edit-mode', 'promo_code')" @cancel="$emit('update:edit-mode', '')" @update="savePromoCodeChange" :requesting-changes="requestingChanges" />
        <mode-switcher v-if="canChangePrice" :edit-mode="priceEditMode" label="Adjust total for this visit" @edit="$emit('update:edit-mode', 'price')" @cancel="$emit('update:edit-mode', '')" @update="savePriceChange" :requesting-changes="requestingChanges" />
      </template>
    </div>
  </div>
</template>
<script>
import ModeSwitcher from '@/shared/mode_switcher.vue'
import Spinner from '@/shared/spinner.vue'
import ExtraCharges from './breakdown/extra_charges.vue'
import HweDetails from './breakdown/hwe_details.vue'
import ParkingFee from './breakdown/parking_fee.vue'
import CancellationFee from './breakdown/cancellation_fee.vue'
import VisitBonus from './breakdown/bonus.vue'
import VisitReimbursement from './breakdown/reimbursement.vue'
import Adjustment from './breakdown/adjustment.vue'
import Discount from './breakdown/discount.vue'
import BundledDiscount from './breakdown/bundled_discount.vue'
import CustomPriceNote from './breakdown/custom_price_note.vue'
export default {
  components: { ExtraCharges, HweDetails, ParkingFee, CancellationFee, VisitReimbursement, ModeSwitcher, Spinner, Adjustment, Discount, BundledDiscount, VisitBonus, CustomPriceNote },
  props: {
    canChangePrice: {
      type: Boolean,
      required: true
    },
    canChangePromoCode: {
      type: Boolean,
      required: true
    },
    cleaningCharges: {
      type: String,
      required: true
    },
    event: {
      type: Object,
      required: true
    },
    editMode: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      required: true
    },
    requestingChanges: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      adjustment: null,
      adjustmentType: '$',
      adjustmentDescription: null,
      adjustmentDescriptionPlaceholder: null,
      adjustmentApplies: null,

      customPromoCode: null,
      discount: null,
      discountAmount: null,
      discountType: '$',
      discountDescription: null,
      discountDescriptionPlaceholder: null,
      discountApplies: null,

      bundledDiscount: null,
      bundledDiscountAmount: null,
      bundledDiscountType: '$',
      bundledDiscountDescription: null,
      bundledDiscountDescriptionPlaceholder: null,

      bonus: 0,
      bonusDescription: null,
      reimbursement: 0,
      reimbursementDescription: null
    }
  },
  computed: {
    hasPromoCode() {
      if (this.event) {
        return (this.event.promo_code) || this.priceEditMode
      }
    },
    hasParkingFee() {
      if (this.event) {
        return !!this.event.parking_details
      }
    },
    priceEditMode() {
      return this.editMode == 'price'
    },
    priceLoading() {
      return this.loading && this.priceEditMode
    },
    promoCode() {
      if (this.event) {
        return this.event.promo_code ? this.event.promo_code : '-'
      }
    },
    promoCodeEditMode() {
      return (this.editMode == 'promo_code') || this.priceEditMode
    },
    totalCleaningCharges() {
      if (this.event) {
        return this.event.total_cleaning_charges
      }
    },
  },
  methods: {
    savePriceChange() {
      var params = {
        promo_code: this.customPromoCode,
        context: 'visit_modal'
      }

      if (this.bundledDiscountDescription) {
        params.bundled_discount_description = this.bundledDiscountDescription
        params.bundled_discount = this.bundledDiscount + this.bundledDiscountType
      } else {
        params.bundled_discount_description = null
        params.bundled_discount = 0
      }

      if (this.discountDescription) {
        params.discount_description = this.discountDescription
        params.discount = this.discount + this.discountType
        params.discount_applies_for_cleaners = this.discountApplies
      } else {
        params.discount_description = null
        params.discount = 0
        params.discount_applies_for_cleaners = false
      }

      if (this.adjustmentDescription) {
        params.adjustment_description = this.adjustmentDescription
        params.adjustment = this.adjustment + this.adjustmentType
        params.adjustment_applies_for_cleaners = this.adjustmentApplies
      } else {
        params.adjustment_description = null
        params.adjustment = 0
        params.adjustment_applies_for_cleaners = false
      }

      this.updateReservation(params)
    },
    savePromoCodeChange() {
      var params = {
        promo_code: this.customPromoCode,
        context: 'visit_modal'
      }
      this.updateReservation(params)
    },
    updateValue({ attr, value }) {
        console.log("breakdown updateValue", attr, value)
      this[attr] = value
    },
    updateReservation(params) {
      this.$emit('update:reservation', params)
    },
    resetValues() {
      this.adjustment = this.event.adjustment
      this.adjustmentDescriptionPlaceholder = null
      this.adjustmentDescription = null

      this.bundledDiscountType = '$'
      this.bundledDiscountDescription = null
      this.bundledDiscountDescriptionPlaceholder = null
      this.bundledDiscountCents = null

      this.discountType = '$'
      this.discountDescription = null
      this.discountDescriptionPlaceholder = null
      this.discountCents = null

      if(this.event.adjustment_description === 'Adjustment'){
        this.adjustmentDescriptionPlaceholder = this.event.adjustment_description
      } else {
        this.adjustmentDescription = this.event.adjustment_description
      }

      if(this.event.bundled_discount_description === 'Bundle'){
        this.bundledDiscountDescriptionPlaceholder = this.event.bundled_discount_description
      } else {
        this.bundledDiscountDescription = this.event.bundled_discount_description
      }

      this.bundledDiscount = this.event.bundled_discount
      if (this.event.bundled_discount_percentage > 0) {
        this.bundledDiscountType = '%'
        this.bundledDiscount = this.event.bundled_discount_percentage
        this.bundledDiscountAmount = this.event.bundled_discount
      }

      if(this.event.discount_description === 'Discount'){
        this.discountDescriptionPlaceholder = this.event.discount_description
      } else {
        this.discountDescription = this.event.discount_description
      }

      this.discount = this.event.discount
      if (this.event.discount_percentage > 0) {
        this.discountType = '%'
        this.discount = this.event.discount_percentage
        this.discountAmount = this.event.discount
      }
      this.discountApplies = this.event.discount_applies_for_cleaners
      this.adjustmentApplies = this.event.adjustment_applies_for_cleaners

      this.bonus = this.event.bonus * 1
      this.bonusDescription = this.event.bonus_description

      this.reimbursement = this.event.reimbursement * 1
      this.reimbursementDescription = this.event.reimbursement_description
    }
  },
  mounted() {
    this.resetValues()
  },
  watch: {
    event: {
      handler(newValue, oldValue) {
        this.resetValues()
      },
      deep: true
    }
  }
}
</script>
