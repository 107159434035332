<template>
  <div v-if="isCarpetCleaning">
    <div class="float-parent gray-text clearfix mb-2" v-for="detail in details">
      <div class="float-left">{{ detail.description }}</div>
      <div class="float-right">{{ detail.cost }}</div>
    </div>
  </div>
</template>
<script>
import { isCarpetCleaning, savedExtras } from '@/utils'
export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    details() {
      if (this.event) {
        return this.event.hwe_details
      }
    },
    isCarpetCleaning() {
      return isCarpetCleaning(this.event)
    },

  }
}
</script>
