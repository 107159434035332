<template>
   <div v-if="detail" class="float-parent font-mid-bold clearfix mt-3 mb-2" v-b-tooltip.hover.html placement="right" :title="parkingFeeTooltipWithPropertyLink">
      <div class="float-left">{{ detail.description }}</div>
      <div class="float-right blue-text">{{ detail.cost }}</div>
      <i id="tooltip" class="fal fa-info-circle blue-text mt-1" style="position: absolute; right: 0"></i>
    </div>
  </div>
</template>
<script>
import infos_mixin from '@/shared/infos_mixin'
export default {
  mixins: [ infos_mixin ],
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    detail() {
      if (this.event) {
        return this.event.parking_details
      }
    },
    propertyLink() {
      return '/properties/' + this.event.location_id
    }
  }
}
</script>
