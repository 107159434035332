<script>
import BaseSelect from '../inputs/base_select.vue'

export default {
  extends: BaseSelect,
  props: {
    cssClass: {
      type: String,
      default: 'round_dropdown'
    }
  },
}
</script>
