<script>
import Dropdown from '../inputs/select.vue'

export default {
  name: 'bathrooms_dropdown',
  extends: Dropdown,
  props: {
    options: {
      type: Array,
      default () {
        return Window.bathroomOptions
      }
    },
    rules: {
      type: String,
      default: 'required'
    },
    label: {
      type: String,
      default: 'Bathrooms'
    },
    name: {
      type: String,
      default: 'bathrooms'
    }
  }
}
</script>
