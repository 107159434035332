<template>
  <div class="row">
    <div class="col-12 mt-4">
      <div class="alert alert-warning" role="alert">
        Please contact us at {{companyEmail}} {{companyPhone}} if you want to cancel your visits
      </div>
    </div>
  </div>
</template>
<script>
import CancellationWidget from './cancellation_widget_for_clients.vue'
import company_phone_mixin from '@/shared/company_phone_mixin'
export default {
  extends: CancellationWidget,
  mixins: [company_phone_mixin]
}
</script>
