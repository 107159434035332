<template>
  <div v-if="isPropertyCleaning">
    <div class="float-parent font-mid-bold clearfix mt-3 mb-2">
      <div class="float-left">Extra Charges</div>
      <div class="float-right blue-text">{{totalExtraCharges}}</div>
    </div>
    <div class="float-parent gray-text clearfix mb-2" v-for="extra in savedExtras">
      <div class="float-left">{{extra.name}} x {{extra.quantity}}</div>
      <div class="float-right">{{extra.cost}}</div>
    </div>
  </div>
</template>
<script>
import { isPropertyCleaning, savedExtras } from '@/utils'
export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    extras() {
      if (this.event) {
        return this.event.extras
      }
    },
    isPropertyCleaning() {
      return isPropertyCleaning(this.event)
    },
    savedExtras() {
      if (this.extras) {
        return savedExtras(this.extras)
      }
    },
    totalExtraCharges() {
      if (this.event) {
        return this.event.total_extra_charges
      }
    }
  }
}
</script>
