<template>
  <div class="row">
    <div class="col-12 mt-2 py-0">
      <p class="blue-text font-weight-bold font-16 text-center my-0 py-0">
        <a class="blue-text font-weight-light my-0 py-0 cursor-pointer" data-toggle="collapse" data-target="#cancel-space">
          <i class="fal fa-plus"></i> &nbsp;More Options
        </a>
      </p>
    </div>
    <div v-show="!collapseForm" class="collapse mx-auto" id="cancel-space">
      <div class="col-12 mt-2 py-0">
        <p class="font-weight-lighter font-16 text-center my-0 py-0">
          <a v-b-toggle="'collapse-form'" class="red-text cursor-pointer" @click="collapseForm = !collapseForm">Cancel</a>
        </p>
      </div>
    </div>
    <my-form ref="form" v-show="collapseForm" :start-in-edit-mode="true" :report-success="false" class="mx-auto">
      <template v-slot:default="{invalid, valid, errors }">
        <div class="col-sm-12 form-group mb-1">
          <basic-input v-model="cancelReason" name="reason" label="Please give us a reason to cancel" rules="required|min:5" class="m-2"></basic-input>
        </div>
      </template>
      <template v-slot:edit-buttons="{ invalid, validated }">
        <div class="form-group">
          <b-button :disabled="invalid && validated" type="submit" @click.passive="range = 'this'" class="btn btn-mini btn-red main-btn m-2">Cancel Visit</b-button>
          <b-button v-if="canCancelSchedule" :disabled="invalid && validated" type="submit" @click.passive="range = 'from_this'" class="btn btn-mini btn-red main-btn m-2">Cancel Schedule</b-button>
          <b-button type="button" @click="collapseForm = !collapseForm" class="btn btn-mini btn-blue main-btn m-2">Back</b-button>
        </div>
        <div class="alert alert-warning" role="alert">
          Please note: there is a 24 hours cancellation period and a cancellation fee of $25, feel free to reschedule to avoid any fees
        </div>
      </template>
    </my-form>
  </div>
</template>
<script>
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
Vue.use(VuejsDialog)
import CancellationWidget from './cancellation_widget.vue'
import MyForm from '@/shared/base_form.vue'
import BasicInput from '@/shared/inputs/text.vue'

export default {
  data() {
    return {
      collapseForm: false
    }
  },
  extends: CancellationWidget,
  components: { MyForm, BasicInput }
}
</script>
