<template>
  <div v-if="hasAdjustment" v-bind="$attrs">
    <template v-if="editMode">
      <div class="float-left col-7">
        <input type="text" class="form-control text-left" :placeholder="placeholder" v-model="ourDescription">
      </div>

      <div class="float-right blue-text col-5">
        <div class="input-group">
          <input type="number" class="form-control text-right" min="0" v-model="ourAdjustment">
          <div class="input-group-append">
            <select class="form-control select" v-model="ourType">
              <option value="$" selected><strong>$</strong></option>
            </select>
          </div>
          <label class="checkbox-set mt-2">
            <input type="checkbox" v-model="ourApplies">
            <span class="checkmark checkmark-blue"></span>
          </label>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="float-left">{{ description ? description : placeholder }} {{ appliesInfo }}</div>
      <div class="float-right blue-text">{{ cents | toCurrency }}</div>
    </template>
  </div>
</template>
<script>

export default {
  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    applies: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    description: {
      type: String
    },
    placeholder: {
      type: String
    },
    adjustment: {
      type: Number|String,
      default: 0
    }
  },
  computed: {
    appliesInfo() {
      if (this.isClient) {
        return
      }
      return this.applies ? '(Applies for Cleaners)' : ''
    },
    cents() {
      return this.adjustment * 100
    },
    hasAdjustment() {
      return (this.adjustment != 0) || this.editMode
    },
    ourAdjustment: {
      get() {
        return this.adjustment
      },
      set(v) {
        this.$emit('update', { attr: 'adjustment', value: v })
      }
    },
    ourApplies: {
      get() {
        return this.applies
      },
      set(v) {
        this.$emit('update', { attr: 'adjustmentApplies', value: v })
      }
    },
    ourDescription: {
      get() {
        return this.description
      },
      set(v) {
        this.$emit('update', { attr: 'adjustmentDescription', value: v })
      }
    },
    ourType: {
      get() {
        return this.type
      },
      set(v) {
        this.$emit('update', { attr: 'adjustmentType', value: v })
      }
    },
  }
}
</script>
