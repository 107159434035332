<template>
  <div v-if="hasBonus" v-bind="$attrs">
    <div class="float-left">
      {{ description }} (Paid by WHC)
    </div>
    <div class="float-right blue-text">{{ formattedAmount }}</div>
  </div>
</template>
<script>
import { formatCurrency } from '@/utils'
export default {
  props: {
    bonus: {
      type: Number|String,
      default: 0
    },
    description: {
      type: String
    }
  },
  computed: {
    hasBonus() {
      return (this.bonus && this.bonus != 0)
    },
    formattedAmount() {
      return formatCurrency(this.bonus * 1)
    }
  }
}
</script>
