<template>
  <div class="row text-center">
    <template v-if="accepted">
      <template v-if="!loading">
        <div class="col-md-12">
          <div class="row">
            <h2 class="col-sm-8 offset-sm-2 col-form-label text-center mt-2 sub-head">Contact Client</h2>
              <div class="col-sm-8 offset-sm-2 full-select">
                <div class="blue-line-select">
                  <dropdown v-model="messageTemplate" :options="actions"></dropdown>
                </div>
              </div>
              <div class="col-12" v-if="showArrivalOptions">
                <b-form-group>
                  <b-form-radio-group
                    id="btn-radios-2"
                    v-model="arrivalStatus"
                    :options="arriveStatusOptions"
                    buttons
                    button-variant="outline-primary"
                    size="md"
                    name="radio-btn-outline"
                  ></b-form-radio-group>
                </b-form-group>
                <h2 class="sub-head text-center">Be there at</h2>
                <div class="col-md-12">
                  <b-time v-model="eta" locale="en"></b-time>
                </div>
                <template v-if="false">
                <p class="cursor-pointer text-primary text-center my-0" v-b-toggle.collapse-message>Preview message <br /> <i class="fal fa-chevron-down"></i></p>
                <b-collapse id="collapse-message" class="mt-2">
                  <div class="chat">
                    <div class="yours messages">
                        <div class="message last"> Hello, this is Wave House Cleaning - Los Angeles, CA.<br>
                          We will arrive at {{eta}}. <br> If you have any concerns you can call us at 480-238-2473. <br>
                          View your reservation here <br>
                          https://shortlink…
                        </div>
                    </div>
                  </div>
                </b-collapse>
                </template>
              </div>
              <div class="col-12" v-if="showCustomMessageField">
                <my-text-area v-model="customMessage" rules="required|min:5" name="Text"></my-text-area>
              </div>
              <div class="col-md-12 my-2">
                <button @click="contactClient" class="btn btn-default btn-primary">Send message</button>
              </div>
          </div>
        </div>
      </template>
      <spinner :show="loading" :use-modal="false" message="Loading"></spinner>
      <div v-if="contactedAgo" class="col-12">
        <small >{{contactedAgo}}</small>
      </div>
    </template>
  </div>
</template>
<script>
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addLocale(en)
import Vue from 'vue'
import api from '@/admins/api'
import Spinner from '@/shared/spinner.vue'
import Flasher from '@/flasher'
import Dropdown from '@/shared/inputs/base_select.vue'
import MyTextArea from '@/shared/inputs/text_area.vue'
import $ from 'jquery'
Vue.use(Flasher)

export default {
  props: {
    jobId: {
      type: Number,
      required: true
    },
    initTemplate: {
      type: String
    },
    initArriveStatus: {
      type: String
    },
    etaFromApi: {
      type: String
    }
  },
  components: { Spinner, Dropdown, MyTextArea },
  computed: {
    accepted() {
      return this.job && this.job.accepted
    },
    contactable() {
      return this.job && this.job.contactable
    },
    contactedAgo() {
      if (this.job.contacted_at) {
        const timeAgo = new TimeAgo('en-US')
        return 'Client last contacted ' + timeAgo.format(new Date(this.job.contacted_at))
      }
    },
    params() {
      let params = {}
      if (this.messageTemplate == 'custom') {
        params.text = this.customMessage
      } else {
        params.template = this.messageTemplate
        if (params.template == 'arrival-status') {
          params.eta = this.eta.replace(/:00$/, '')
          params.status = this.arrivalStatus
        }
      }
      return params
    },
    showArrivalOptions() {
      return this.messageTemplate == 'arrival-status'
    },
    showCustomMessageField() {
      return this.messageTemplate == 'custom'
    }
  },
  data() {
    return {
      job: {},
      loading: false,
      eta: new Date().toString('HH:mm:00'),
      messageTemplate: 'at-the-door',
      customMessage: '',
      actions: [
        { label: "I'm at the door", value: 'at-the-door' },
        { label: "Arrive status message", value: 'arrival-status' },
        { label: 'Custom Message', value: 'custom' },
      ],
      arriveStatusOptions: [
          { text: 'On my way', value: 'on-my-way' },
          { text: 'Late', value: 'late' },
        ],
      arrivalStatus: 'on-my-way'
    }
  },
  mounted() {
    this.loadJob(() => {
      this.setTemplate(this.initTemplate)
      this.setStatus(this.initArriveStatus)
    })
  },
  methods: {
    loadJob(callback) {
      this.loading = true
      api.getCleaningJob(this.jobId).then((response) => {
        this.job = response.data
        this.loading = false
        this.reset()
        if (callback) {
          callback()
        }
      }, (error) => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
      })
    },
    reset() {
      this.customMessage = ''
      this.messageTemplate = 'at-the-door'
      this.arrivalStatus = 'on-my-way'
    },
    doContactClient(force = false) {
      this.loading = true
      const apiFunc = force ? api.forceContactCleaningJobClient : api.contactCleaningJobClient
      apiFunc(this.jobId, this.params).then(() => {
        this.$flasher.success('Text sent to client')
        this.loadJob()
      }, (error) => {
        this.loading = false
        this.$flasher.errors(error.response.data.errors)
      }).catch((e) => {
        this.$flasher.errors(e)
        this.loading = false
      })
    },
    contactClient() {
      this.$dialog
        .confirm('Are you sure you want to send this message?', {
          okText: 'Yes',
          cancelText: 'No'
        })
        .then((dialog) => {
            if (this.contactable) {
              this.doContactClient()
            } else {
              this.$dialog.confirm(this.job.client_name +' Doesn\'t start until ' + this.job.duration + ' arrival time, do you want to proceed?', {
                okText: 'Yes',
                cancelText: 'No'
              }).then((dialog) => {
                this.doContactClient(true)
              })
            }
        })
    },
    autoContact() {
      this.loading = true
      api.forceContactCleaningJobClient(this.jobId, this.params).then(() => {
        this.reset()
        this.$flasher.success('Text sent to client')
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    setEtaFromApi(newEta) {
      if (newEta) {
        this.eta = newEta
      } else {
        this.eta = new Date().toString('HH:mm:00')
      }
    },
    setTemplate(newTemplate) {
      if (newTemplate) {
        this.messageTemplate = newTemplate
      } else {
        this.messageTemplate = 'at-the-door'
      }
    },
    setStatus(newStatus) {
      if (newStatus) {
        if ( (newStatus == 'on-my-way') || (newStatus == 'late') ) {
          this.setTemplate('arrival-status')
          this.arrivalStatus = newStatus
        } else {
          console.error("Invalid initArrivalStatus", newStatus)
        }
      } else {
        this.arrivalStatus = 'on-my-way'
      }
    }
  },
  watch: {
    etaFromApi(newValue) {
      this.setEtaFromApi(newValue)
    },
    initTemplate(newValue) {
      this.setTemplate(newValue)
    },
    initArriveStatus(newValue) {
      this.setStatus(newValue)
    }
  }
}
</script>
