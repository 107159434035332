<template>
  <div v-if="event" class="px-2 tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
    <div class="map-container">
      <img class="img-fluid img-map-modal-rounded" :src="mapImgSrc">
      <div v-if="isCleaner" class="text-center"><a v-if="mapDirUrl" :href="mapDirUrl" target="_blank" class="btn btn-link">Map Directions</a></div>
      <div class="mt-3">
        <div>
          <h2 class="sub-head text-center mb-4">Cleaning Plan</h2>
        </div>
        <div class="p-2">
          <div class="mx-auto clean-plan-sec">
            <spinner :show="planLoading" :use-modal="false" message="Loading"></spinner>
            <template v-if="!planLoading">
              <div class="row clean-plan-sec mb-2" v-if="planEditMode">
                <div class="col-sm-6 my-1"> <!-- change to sm-4 when the move card is enabled -->
                  <div class="clean-plan cursor-pointer bg-hover" :class="[customPlan === 'standard' ? 'active': '']" @click="[customPlan = 'standard']">
                    <div class="row d-flex">
                      <div class="align-self-center col-md-12">
                        <img src="@images/initial-house.png" alt="Initial house" class="image-fluid max-width-100">
                      </div>
                      <div class="align-self-center col-md-12">
                        <div class="clearfix">
                          <h2 class="sub-head pt-2 mb-0 pb-0">Standard</h2>
                          <div class="checkbox-group ml-3">
                            <label class="checkbox-set">
                              <input type="radio" value="standard" v-model="customPlan">
                              <span class="checkmark checkmark-blue"></span>
                            </label>
                          </div>
                        </div>
                        <p class="green-text font-16"></p>
                      </div>
                    </div>
                  </div>
                </div>

                <template v-if="upkeepAllowed">
                  <div class="col-sm-6 my-1"> <!-- change to sm-4 when the move card is enabled -->
                    <div class="clean-plan cursor-pointer bg-hover" :class="[customPlan === 'upkeep' ? 'active': '']" @click="[customPlan = 'upkeep']">
                      <div class="row d-flex">
                        <div class="align-self-center col-md-12">
                          <img src="@images/upkeep-house.png" alt="Upkeep house" class="image-fluid max-width-100">
                        </div>
                        <div class="align-self-center col-md-12">
                          <div class="clearfix">
                            <h2 class="sub-head pt-2 mb-0 pb-0">Upkeep</h2>
                            <div class="checkbox-group ml-3 clearfix">
                              <label class="checkbox-set">
                                <input type="radio" value="upkeep" v-model="customPlan">
                                <span class="checkmark checkmark-blue"></span>
                              </label>
                            </div>
                            <p class="green-text font-16"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="false" class="col-sm-4 my-1">
                    <div class="clean-plan cursor-pointer bg-hover" :class="[customPlan === 'move' ? 'active': '']" @click="[customPlan = 'move']">
                      <div class="row d-flex">
                        <div class="align-self-center col-md-12">
                          <img src="@images/upkeep-house.png" alt="Move house" class="image-fluid max-width-100">
                        </div>
                        <div class="align-self-center col-md-12">
                          <div class="clearfix">
                            <h2 class="sub-head pt-2 mb-0 pb-0">Move In/Out</h2>
                            <div class="checkbox-group ml-3 clearfix">
                              <label class="checkbox-set">
                                <input type="radio" value="move" v-model="customPlan">
                                <span class="checkmark checkmark-blue"></span>
                              </label>
                            </div>
                            <p class="green-text font-16"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div v-if="!planEditMode" class="clean-plan active">
                <div class="row d-flex">
                  <div class="align-self-center col-md-6 col-sm-12">
                    <img v-if="imageRequestedCleaning == 'initial'" class="image-fluid max-width-200" src="@images/initial-house.png" alt="Initial house">
                    <img v-if="imageRequestedCleaning == 'upkeep'" class="image-fluid max-width-200" src="@images/upkeep-house.png" alt="Upkeep house">
                    <img v-if="imageRequestedCleaning == 'move'" class="image-fluid max-width-200" src="@images/upkeep-house.png" alt="Move house">
                  </div>
                  <div class="align-self-center col-md-6 col-sm-12">
                    <div class="clearfix">
                      <h2 class="sub-head pt-2" :class="statusColor+'-text'">{{planWithPrice}}</h2>
                      <p class="green-text font-16 font-mid-bold mb-0">{{startAsDuration}}</p>
                      <p class="green-text font-16">{{schedule}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <mode-switcher v-if="canUpdateOrRequestChanges" :edit-mode="planEditMode" label="Change plan for this visit" @edit="editMode = 'plan'" @cancel="editMode = ''" @update="savePlanChange" :requesting-changes="requestingChanges" />
            </template>
          </div>
        </div>
      </div>
    </div>

    <extras v-if="isPropertyCleaning" :saved-extras="event.saved_extras" @save="saveExtras" :allowed-extra-ids="allowedExtraIds" :can-change-extras="canUpdateOrRequestChanges" class="box-wrapper mt-4" :can-remove-extras="canRemoveExtras" :property-id="propertyId" :update-text="updateButtonText" @update:edit-mode="onUpdateEditMode" />

    <div class="box-wrapper mt-4">
      <cleaning-breakdown
        :can-change-price="canChangePrice"
        :can-change-promo-code="canChangePromoCode"
        :cleaning-charges="cleaningCharges"
        :custom-prices="customPrices"
        :event="event"
        :loading="loading"
        :edit-mode="editMode"
        :requesting-changes="requestingChanges"
        @update:edit-mode="editMode = $event"
        @update:reservation="updateReservation"
      ></cleaning-breakdown>
    </div>
    <div class="box-wrapper mt-4">
      <div class="px-2 mt-3 col-md-8 offset-md-2">
        <div class="my-3">
          <div>
            <h2 class="sub-head text-center mb-4">Property Details</h2>
          </div>
        </div>
        <spinner :show="propertyLoading" :use-modal="false" message="Loading"></spinner>
      </div>
      <visit-warning v-if="propertyEditMode" :property-id="propertyId" context="visit-property-details"></visit-warning>
      <div class="px-2 mt-3 col-md-8 offset-md-2">
        <template v-if="!propertyLoading">
          <div class="row">
            <label class="col-6 col-sm-6 col-form-label text-right">Address</label>
            <label class="col-6 col-sm-6 col-form-label font-bold">{{address}}</label>
          </div>
          <div class="row">
            <label class="col-6 col-sm-6 col-form-label text-right">Zip Code</label>
            <label class="col-6 col-sm-6 col-form-label font-bold">{{zipCode}}</label>
          </div>
          <div class="row">
            <label class="col-6 col-sm-6 col-form-label text-right">Bedrooms</label>
            <div class="col-6 col-sm-6"><bedrooms :readonly="readonly || !propertyEditMode" :hide-label="true" v-model="bedrooms"/></div>
          </div>
          <div class="row">
            <label class="col-6 col-sm-6 col-form-label text-right">Bathrooms</label>
            <div class="col-6 col-sm-6"><bathrooms :readonly="readonly || !propertyEditMode" :hide-label="true" v-model="bathrooms"/></div>
          </div>
          <div class="row">
            <label class="col-6 col-sm-6 col-form-label text-right">Area</label>
            <div class="col-6 col-sm-6"><area-dropdown :readonly="readonly || !propertyEditMode" :hide-label="true" v-model="square_feet"/></div>
          </div>
          <div v-if="false" class="row">
            <label class="col-6 col-sm-6 col-form-label text-right">File</label>
            <label class="col-6 col-sm-6 col-form-label font-bold"><div class="mb-1" v-html="attachment"></div></label>
          </div>

          <mode-switcher v-if="canUpdate" :edit-mode="propertyEditMode" label="Edit property details for this visit" @edit="editMode = 'property'" @cancel="editMode = ''" @update="savePropertyCustomization" :requesting-changes="requestingChanges" />
        </template>
      </div>
    </div>
    <cancellation-widget v-if="isClient" :event="event" user-type="Client" @exit-cancel="action = ''" @do-cancel="doCancel(...arguments)"></cancellation-widget>
    <div class="my-2 text-center">
      <a class="text-light-color cursor-pointer" href="https://www.wavehousecleaning.com/cancellation-policy/" target="_blank"><i class="fal fa-info-circle"></i> Cancellation Policy</a>
    </div>
  </div>
</template>
<script>
import Spinner from '@/shared/spinner.vue'
import ModeSwitcher from '@/shared/mode_switcher.vue'
import VisitWarning from '@/shared/visit_warning.vue'
import Extras from '@/shared/updateable_visit_extras.vue'
import { isPropertyCleaning } from '@/utils'
import CancellationWidget from './disabled_cancellation_widget_for_clients.vue'
import Bathrooms from '@/shared/fields/bathrooms.vue'
import Bedrooms from '@/shared/fields/bedrooms.vue'
import CleaningBreakdown from './cleaning_breakdown.vue'
import AreaDropdown from '@/shared/fields/area.vue'
import permissions_mixin from '@/shared/permissions_mixin'

export default {
  mixins: [ permissions_mixin ],
  data() {
    return {
      bedrooms: null,
      bathrooms: null,
      square_feet: null,
      customPlan: null,
      customSomeoneHome: null,
      editMode: '',
      cancelReason: { text: '' }
    }
  },
  props: {
    event: {},
    loading: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    },
    permissions: {
      type: Object,
      required: true
    },
    requestingChanges: {
      type: Boolean,
      default: false
    },
    showMap: {
      type: Boolean,
      default: true
    }
  },
  components: { Extras, ModeSwitcher, Spinner, VisitWarning, CancellationWidget, Bathrooms, Bedrooms, AreaDropdown, CleaningBreakdown },
  computed: {
    address() {
      if (this.event) {
        return this.event.address
      }
    },
    allowedExtraIds() {
      if (this.event) {
        return this.event.allowed_custom_extra_ids
      }
    },
    attachment() {
      if (this.event) {
        return this.event.attachment
      }
    },
    cancelled() {
      return this.event && this.event.cancelled
    },
    canUpdateOrRequestChanges() {
      return this.canUpdate || (this.canRequestChanges && this.requestingChanges)
    },
    canRemoveExtras() {
      return this.canUpdateOrRequestChanges
    },
    cleaningCharges() {
      if (this.event) {
        return this.event.cleaning_charges
      }
    },
    customPrices() {
      if (this.event && this.event.custom_prices) {
        return this.event.custom_prices
      }
    },
    isPropertyCleaning() {
      return isPropertyCleaning(this.event)
    },
    startAsDuration() {
      if (this.event) {
        return this.event.formatted_start_date + ' at ' + this.event.duration + ' arrival'
      }
    },
    email() {
      return this.event.client_email
    },
    mapImgSrc() {
      if (this.event) {
        return this.event.map_img_src
      }
    },
    mapDirUrl() {
      if (this.event) {
        return this.event.map_dir_url
      }
    },
    planEditMode() {
      return this.editMode == 'plan'
    },
    statusColor() {
      if (this.event) {
        return this.event.status_color
      }
    },
    imageRequestedCleaning() {
      if (this.event) {
        return this.event.plan_icon
      }
    },
    planWithPrice() {
      if (this.event) {
        return this.event.pretty_requested_cleaning + '(' + this.cleaningCharges + ')'
      }
    },
    propertyId() {
      if (this.event) {
       return this.event.location_id
      }
    },
    propertyEditMode() {
      return this.editMode == 'property'
    },
    someoneHome() {
      if (this.event) {
        return this.event.someone_home_str
      }
    },
    schedule() {
      if (this.event) {
        return this.event.schedule_summary
      }
    },
    zipCode() {
      if (this.event) {
        return this.event.zip_code
      }
    },
    planLoading() {
      return this.loading && this.planEditMode
    },
    propertyLoading() {
      return this.loading && this.propertyEditMode
    },
    readonly() {
      return this.locked
    },
    updateButtonText() {
      if (this.requestingChanges) {
        return 'Request Change'
      }
    },
  },
  methods: {
    doCancel(what, event, reasonText, noEmail, chargeFee) {
      this.$emit('do-cancel', what, event, reasonText, noEmail, chargeFee)
    },
    saveExtras({ changedExtras, changes }) {
      if (this.requestingChanges) {
        this.$emit('request-changes', { what: 'extras', changes: changes })
      } else {
        this.updateReservation({ extras: JSON.stringify(changedExtras), context: 'visit_modal' })
      }
    },
    savePlanChange() {
      if (this.requestingChanges) {
        this.$emit('request-changes', { what: 'plan', new_plan: this.customPlan })
      } else {
        this.updateReservation({ requested_cleaning: this.customPlan, context: 'visit_modal' })
      }
    },
    savePropertyCustomization() {
      const params = {
        context: 'visit_modal',
        bathrooms: this.bathrooms,
        bedrooms: this.bedrooms,
        square_feet: this.square_feet,
        someone_home: this.customSomeoneHome
      }
      this.updateReservation(params)
    },
    updateReservation(params) {
      this.$emit('update:reservation', params)
    },
    initLocalData(data) {
      if (data == null) {
        return
      }
      this.customPlan = data.plan
      this.customPromoCode = data.promo_code
      this.customSomeoneHome = data.someone_home
      this.square_feet = data.square_feet
      this.bathrooms = data.bathrooms
      this.bedrooms = data.bedrooms
      this.editMode = ''
    },
    onUpdateEditMode(newMode) {
      this.$emit('update:edit-mode', newMode)
    }
  },
  mounted() {
    this.initLocalData(this.event)
  },
  watch: {
    editMode(newValue) {
      this.$emit('update:edit-mode', !!newValue)
    },
    loading(newValue) {
      if (newValue === false) {
        this.editMode = null
      }
    }
  }
}
</script>
