<template>
  <div v-if="hasDiscount" v-bind="$attrs">
    <template v-if="editMode">
      <div class="float-left col-7">
        <input type="text" class="form-control text-left" :placeholder="placeholder" v-model="ourDescription">
      </div>

      <div class="float-right blue-text col-5">
        <div class="input-group">
          <input type="number" class="form-control text-right" max="0" v-model="ourDiscount">
          <div class="input-group-append">
            <select class="form-control select" v-model="ourType">
              <option value="$" selected><strong>$</strong></option>
              <option value="%"><strong>%</strong></option>
            </select>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="float-left">{{ discountDescription }}</div>
      <div class="float-right blue-text">{{ formattedDiscount }}</div>
    </template>
  </div>
</template>
<script>
import { formatCurrency } from '@/utils'
export default {
  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    amount: {
      type: Number,
    },
    description: {
      type: String
    },
    placeholder: {
      type: String
    },
    discount: {
      type: Number|String,
      default: 0
    }
  },
  computed: {
    cents() {
      return this.discount * 100
    },
    discountDescription() {
      let text = this.description ? this.description : this.placeholder
      if (this.amount) {
        return this.discount + '% bundle (' + text + ')'
      }
      return text
    },
    formattedDiscount() {
      if (this.type == '$') {
        return formatCurrency(this.discount)
      }
      return formatCurrency(this.amount)
    },
    hasDiscount() {
      return (this.discount != 0) || this.editMode
    },
    ourDiscount: {
      get() {
        return this.discount
      },
      set(v) {
        this.$emit('update', { attr: 'bundledDiscount', value: v })
      }
    },
    ourDescription: {
      get() {
        return this.description
      },
      set(v) {
        this.$emit('update', { attr: 'bundledDiscountDescription', value: v })
      }
    },
    ourType: {
      get() {
        return this.type
      },
      set(v) {
        this.$emit('update', { attr: 'bundledDiscountType', value: v })
      }
    },
  }
}
</script>
