<template>
  <div>
    <template v-if="meetupAvailable">
      <div v-if="isStaff" class="row">
        <div class="col-6 text-center">
          <button @click="sendMeetupUrlToClient" class="btn btn-warning">Send meetup URL to client</button>
        </div>
        <div class="col-6 text-center">
          <button @click="sendMeetupUrlToCleaner" class="btn btn-warning">Send meetup URL to cleaner</button>
        </div>
      </div>
      <div v-if="isStaff" class="row my-4">
        <div class="col-12 text-center">Short Meetup URLs:</div>
        <div class="col-6 text-center">For client: <a :href="clientMeetupUrl">{{ clientMeetupUrl }}</a></div>
        <div class="col-6 text-center">For cleaner: <a :href="cleanerMeetupUrl">{{ cleanerMeetupUrl }}</a></div>
        <div class="col-12 text-center">
          <small class="text-danger">Share these URLs carefully, because it gives full access to the user's account!</small>
        </div>
      </div>
      <div v-if="isCleaner && hasMeetupCoords" class="alert alert-info text-center my-2">The marker with the dot marks the location where the client wants to meet</div>
      <div v-if="isClient" class="alert alert-info text-center">Move the marker with the dot to the location where you want to meet the cleaner</div>
    </template>
    <div class="map-container">
      <spinner :show="loading" :use-modal="true" message="Please wait"></spinner>
      <div v-if="meetupAvailable" class="row">
        <div v-if="isStaff" class="col-6 text-center">
          Showing directions for {{ showingAs }} <button @click="toggleShowingAs" class="btn btn-link">Toggle</button>
        </div>
        <template v-if="isStaff">
          <div v-if="cleanerLocationDisabled" class="col-6 text-center text-danger font-bold">Cleaner's location disabled</div>
          <div v-if="clientLocationDisabled" class="col-6 text-center text-danger font-bold">Client's location disabled</div>
          <div v-if="error" class="col-6 text-center text-danger font-bold">{{ error }}</div>
          <div v-if="warning" class="col-6 text-center text-alert font-bold">{{ warning }}</div>
        </template>
      </div>
      <gmap-map v-show="meetupAvailable" ref="mapRef" :options="mapOptions" v-bind="gmapParams">
        <div style="display: none">
          <div class="controls drive-control">
            <button class="drive-control-walk" title="Walk Mode">
              <i class="fal fa-walking"></i>
            </button>
            <button class="drive-control-car" title="Car Mode">
              <i class="fal fa-car"></i>
            </button>
          </div>
          <div class="controls destinations-control">
            <span>Destinations: </span>
            <button
              class="destinations-control-client"
              title="Center client location"
            >
              Client
            </button>
            <button
              class="destinations-control-property"
              title="Center property location"
            >
              Property
            </button>
            <button
              class="destinations-control-meetup"
              title="Center meetup location"
            >
              Meetup
            </button>
          </div>
        </div>
        <gmap-polyline
          v-if="breadcrumbs.length > 0"
          :path="breadcrumbsPath"
          strokeColor="blue"
          :strokeOpacity="0.7"
          :strokeWeight="2"
          :visible="showBreadcrumbs"
        >
        </gmap-polyline>
        <gmap-marker
          v-for="(m, idx) in breadcrumbs"
          :position="m.position"
          :key="'breadcrumb_' + idx"
          :label="idx + ''"
          :icon="breadcrumbIcon"
          :clickable="true"
          :visible="showBreadcrumbs"
          @mouseover="showInfoWindow(m, idx)"
          @mouseout="hideInfoWindow"
        ></gmap-marker>
        <gmap-info-window
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
          :content="infoContent"
        ></gmap-info-window>
        <gmap-marker
          :position="propertyCoords"
          :z-index="999"
          title="Property"
          :icon="svgMarker"
        ></gmap-marker>
        <gmap-marker
          v-if="!hasDirections"
          :position="clientCoords"
          :z-index="999"
          title="Client"
          label="Client"
        ></gmap-marker>
        <gmap-marker
          v-if="!hasDirections"
          :position="cleanerCoords"
          :z-index="999"
          title="Cleaner"
          label="Cleaner"
        ></gmap-marker>
        <gmap-marker
          :position="meetupPosition"
          :z-index="999"
          :draggable="canUpdateMeetupPosition"
          title="Meetup with Cleaner"
          @dragstart="onStartDragMeetupMarker"
          @dragend="onEndDragMeetupMarker"
        ></gmap-marker>
        <gmap-marker v-for="(m, i) in meetupMarkers"
          :position="m.position"
          :label="m.label"
          :clickable="true"
          :draggable="false"
          :key="m.id"
          @click="toggleInfoWindow(m,i)"
          ></gmap-marker>
        <DirectionsRenderer
          ref="directionsRef"
          :origin="origin"
          :destination="destinationCoords"
          :panel="$refs.panel"
          :options="directionsRenderedOptions"
          @update:directions="directions = $event"
        />
      </gmap-map>
      <h6 v-if="!meetupAvailable" class="alert alert-info text-center">{{ unavailableMessage }}</h6>
    </div>
    <div v-show="meetupAvailable" ref="panel"></div>
  </div>
</template>
<script>
import Vue from 'vue'
import Spinner from '@/shared/spinner.vue'
import mapIcon from '@images/cleaner-map-icon.png'
import DirectionsRenderer from '@/shared/DirectionsRenderer.js'
import api from '@/admins/api'
import cleanerApi from '@/cleaners/api'
import clientApi from '@/clients/api'
import pubsub_mixin from '@/shared/pubsub_mixin'
import LocationMap from '@/shared/location_map.vue'
export default {
  extends: LocationMap,
  components: {DirectionsRenderer, Spinner},
  mixins: [pubsub_mixin],
  props: {
    showDirections: {
      type: Boolean,
      default: true
    },
    jobId: {
      type: Number
    },
    liveUpdate: {
      type: Boolean,
      default: true
    },
    visitId: {
      type: Number,
      required: true
    },
    propertyId: {
      type: Number
    },
    propertyCoords: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      cleanerMeetupUrl: null,
      clientMeetupUrl: null,
      cleanerArrived: false,
      directions: null,
      destination: null,
      enabled: false,
      loading: false,
      available: false,
      hasData: false,
      hasJob: false,
      meetupCoords: null,
      cleanerCoords: null,
      clientCoords: null,
      showingAs: null,
      cleanerLocationDisabled: null,
      clientLocationDisabled: null,
      travelMode: 'DRIVING',
      error: null,
      warning: null,
      infoContent: '',
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: 0
        }
      },
      currentMidx: null,
      currentPos: 0,
      showBreadcrumbs: false,
      breadcrumbs: {},
    }
  },
  mounted() {
    if (this.isStaff) {
      this.showingAs = 'cleaner'
    }
    this.subscribeToUpdates()
    this.getMeetupDetails()
    this.$root.$on('google-map-loaded', this.initMeetUp)
    this.$refs.mapRef.$mapPromise.then((map) => {
      this.addCustomUI()
    })
  },
  computed: {
    breadcrumbsPath() {
      return this.breadcrumbs.map((b) => b.position)
    },
    breadcrumbIcon() {
      return {
        path: this.google.maps.SymbolPath.CIRCLE,
        scale: 4,
      }
    },
    meetupAvailable() {
      return this.available && this.hasData
    },
    canUpdateMeetupPosition() {
      return this.isClient || this.isStaff
    },
    hasClientCoords() {
      return !!this.clientCoords && !!this.clientCoords.lat && !!this.clientCoords.lng
    },
    hasMeetupCoords() {
      return !!this.meetupCoords && !!this.meetupCoords.lat && !!this.meetupCoords.lng
    },
    unavailableMessage() {
      if (!this.hasData) {
        return 'No data available'
      }
      if (this.hasJob) {
        return 'Available 3 hours before the start of cleaning for accepted jobs'
      }
      return 'No cleaner is assigned'
    },
    svgMarker() {
      return {
        path: "M8.4,0A8.32,8.32,0,0,0,0,8.4v.3a7,7,0,0,0,.5,2.5c1.8,5.9,7.9,13.5,7.9,13.5s6.1-7.6,7.9-13.5a7,7,0,0,0,.5-2.5V8.4A8.32,8.32,0,0,0,8.4,0Zm4.5,10.6a.61.61,0,0,1-.62.58H9.78a.61.61,0,0,1-.63-.58V8.29A.11.11,0,0,0,9,8.18H7.78c-.08,0-.13,0-.13.11V10.6a.61.61,0,0,1-.62.58H4.53a.61.61,0,0,1-.63-.58V6.19a1.67,1.67,0,0,1,.72-1.36L8.17,2.25a.41.41,0,0,1,.46,0l3.56,2.58a1.69,1.69,0,0,1,.71,1.36Z",
        fillColor: "skyblue",
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 2,
      }
    },
    destinationCoords() {
      this.error = null
      this.warning = null
      if (this.enabled) {
        if (this.destination == 'client') {
          if (this.hasClientCoords) {
            return this.clientCoords
          } else {
            this.error = "Missing client location"
          }
        }
        if (this.destination == 'meetup') {
          if (this.hasMeetupCoords) {
            return this.meetupCoords
          } else {
            this.warning = "No meetup location, using property"
          }
        }
      }
      return this.propertyCoords
    },
    gmapParams() {
      return { zoom: this.zoomValue, class: "map-responsive", center: this.destinationCoords }
    },
    origin() {
      if (this.enabled) {
        return this.ourPosition
      }
    },
    directionsRenderedOptions() {
      return {
        draggable: false,
        travelMode: this.travelMode,
        suppressMarkers: !this.showDirections,
        suppressPolylines: !this.showDirections
      }
    },
    hasDirections() {
      return this.directions && this.directions.status == 'OK'
    },
    mapOptions() {
      return {
        fullscreenControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        zoom: 12,
        zoomControl: true,
        minZoom: 3,
        maxZoom: 20,
      }
    },
    meetupIconValue() {
      return mapIcon
    },
    meetupMarkers() {
      if (this.showDirections) {
        return []
      }
      return [
        { position: this.ourPosition, label: { text: 'A', color: 'white', fontSize: '16px' }, name: 'Your detected location' },
        { position: this.theirPosition, label: { text: 'B', color: 'white', fontSize: '16px' }, name: 'Destination' },
      ]
    },
    meetupPosition() {
      if (this.meetupCoords) {
        return this.meetupCoords
      }
      return this.propertyCoords
    },
    ourPosition() {
      if (this.forcedCleaner) {
        return this.cleanerCoords
      }
      if (this.isClient || this.forcedClient) {
        return this.clientCoords
      } else {
        return this.cleanerCoords
      }
    },
    theirPosition() {
      if (this.forcedCleaner) {
        return this.clientCoords
      }
      if (this.isClient || this.forcedClient) {
        return this.cleanerCoords
      } else {
        return this.clientCoords
      }
    },
    forcedCleaner() {
      return this.showingAs == 'cleaner'
    },
    forcedClient() {
      return this.showingAs == 'client'
    },
    travelModeLabel() {
      if (this.travelMode == 'DRIVING') {
        return 'driving'
      }
      return 'walking'
    }
  },
  methods: {
    addCustomUI() {
      const bkgColor = '#fff'
      const border = '2px solid #fff'
      const fontSize = '16px'
      const padding = '15px'
      const textColor = 'rgb(25,25,25)'

      let controlDiv = document.createElement('div')
      let controlUI = document.createElement('div')
      controlUI.classList.add("controls");
      controlUI.style.backgroundColor = bkgColor
      controlUI.style.border = border
      controlUI.style.cursor = 'pointer'
      controlUI.style.textAlign = 'center'
      controlUI.title = 'Click to recenter the map'
      controlDiv.appendChild(controlUI)

      let controlText = document.createElement('div')
      controlText.style.color = textColor
      controlText.style.fontSize = fontSize
      controlText.style.paddingLeft = padding
      controlText.style.paddingRight = padding
      controlText.innerHTML = 'Center'
      controlUI.appendChild(controlText)
      controlUI.addEventListener('click', this.centerMap)
      this.map.controls[this.google.maps.ControlPosition.TOP_LEFT].push(controlDiv)

      const driveControlDiv = document.querySelector(".drive-control");
      let walkingMode = document.querySelector(".drive-control-walk");
      walkingMode.addEventListener('click', () => {
        this.setWalkingMode();
      });

      let carMode = document.querySelector(".drive-control-car");
      carMode.addEventListener('click', () => {
        this.setDrivingMode();
      });

      this.map.controls[this.google.maps.ControlPosition.LEFT_BOTTOM].push(
        driveControlDiv
      );

      if (this.isStaff) {
        let brDiv = document.createElement('div')
        brDiv.style.margin = '10px'

        let brUI = document.createElement('div')
        brUI.classList.add("controls");
        brUI.style.backgroundColor = bkgColor
        brUI.style.paddingLeft = padding
        brUI.style.paddingRight = padding
        brUI.style.cursor = 'pointer'
        brUI.style.textAlign = 'center'
        brDiv.appendChild(brUI)

        let brControlText = document.createElement('div')
        brControlText.style.color = textColor
        brControlText.style.fontSize = fontSize
        brControlText.innerHTML = '<input type="checkbox"></input> Show breadcrumbs'
        brUI.appendChild(brControlText)
        brUI.addEventListener('change', (e) => {
          this.showBreadcrumbs = e.target.checked
        })

        this.map.controls[this.google.maps.ControlPosition.BOTTOM_CENTER].push(brDiv)

        const destinationsControlDiv = document.querySelector(".destinations-control");
        let clientText = document.querySelector(".destinations-control-client");
        clientText.addEventListener('click', () => {
          destinationsControlDiv.classList.remove("destinations-control-is-property");
          destinationsControlDiv.classList.remove("destinations-control-is-meetup");
          destinationsControlDiv.classList.add("destinations-control-is-client");
          this.destination = 'client'
        })

        let propertyText = document.querySelector(".destinations-control-property");
        propertyText.addEventListener('click', () => {
          destinationsControlDiv.classList.remove("destinations-control-is-client");
          destinationsControlDiv.classList.remove("destinations-control-is-meetup");
          destinationsControlDiv.classList.add("destinations-control-is-property");
          this.destination = 'property'
        })

        let meetupText = document.querySelector(".destinations-control-meetup");
        meetupText.addEventListener('click', () => {
          destinationsControlDiv.classList.remove("destinations-control-is-property");
          destinationsControlDiv.classList.remove("destinations-control-is-client");
          destinationsControlDiv.classList.add("destinations-control-is-meetup");
          this.destination = 'meetup'
        })

        this.map.controls[this.google.maps.ControlPosition.TOP_CENTER].push(destinationsControlDiv);
      }
    },
    setDrivingMode() {
      this.travelMode = 'DRIVING'
    },
    setWalkingMode() {
      this.travelMode = 'WALKING'
    },
    centerMap() {
      if (this.destinationCoords) {
        this.map.panTo(this.destinationCoords)
      }
    },
    close() {
      this.showArrivedModal = false
    },
    onEndDragMeetupMarker(e) {
      this.updateMeetupPosition(e)
    },
    onStartDragMeetupMarker() {
    },
    toggleShowingAs() {
      if (this.showingAs == 'client') {
        this.showingAs = 'cleaner'
      } else {
        this.showingAs = 'client'
      }
    },
    initMeetUp() {
      this.enabled = true
    },
    updateMeetupPosition(e) {
      this.meetupCoords = { lat: e.latLng.lat(), lng: e.latLng.lng() }
      this.loading = true
      if (this.propertyId) {
        api.updateProperty(this.propertyId, { property: { meetup_coords: this.meetupCoords } }).then(() => {
          this.loading = false
        })
      } else {
        console.error("PropertyId is required to update the meetup position")
      }
    },
    getMeetupDetails() {
      if (this.jobId) {
        this.loading = true
        api.getJobMeetupDetails(this.jobId).then((response) => {
          this.meetupCoords = response.data.meetup_coords
          this.cleanerCoords = response.data.cleaner_coords
          this.clientCoords = response.data.client_coords
          this.available = response.data.meetup_available
          if (this.isStaff) {
            const hours = new Date().getHours()
            this.available = (hours >= 6) && (hours <= 20)
          }
          this.cleanerMeetupUrl = response.data.cleaner_meetup_url
          this.clientMeetupUrl = response.data.client_meetup_url
          this.cleanerLocationDisabled = response.data.cleaner_location_disabled
          this.clientLocationDisabled = response.data.client_location_disabled
          this.cleanerArrived = response.data.cleanerArrived
          this.hasJob = true
          this.hasData = true
          this.loading = false
          this.getBreadcrumbs()
        }).catch(error => {
          this.meetupCoords = null
          this.cleanerCoords = null
          this.clientCoords = null
          this.cleanerMeetupUrl = null
          this.clientMeetupUrl = null
          this.cleanerLocationDisabled = null
          this.clientLocationDisabled = null
          this.cleanerArrived = false
          this.hasJob = true
          this.hasData = false
          this.loading = false
        })
      }
    },
    getBreadcrumbs() {
      if (this.isStaff) {
        this.loading = true
        api.getJobPositions(this.jobId).then((response) => {
          this.breadcrumbs = response.data.breadcrumbs
          this.loading = false
        })
      }
    },
    sendMeetupUrlToCleaner() {
      api.sendMeetupUrlToCleaner(this.visitId).then(response => {})
    },
    sendMeetupUrlToClient() {
      clientApi.sendMeetupUrlToClient(this.visitId).then(response => {})
    },
    subscribeToUpdates() {
      if (!this.subscribed && this.jobId) {
        this.fayeSubscribe('/meetup/' + this.jobId, 'updated', (channel, msg) => {
          if (this.available) {
            this.getMeetupDetails()
          }
        })
        this.fayeSubscribe('/visits/' + this.visitId + '/job_updated', 'meetup job updated', (channel, msg) => {
          this.getMeetupDetails()
        })
        this.subscribed = true
      }
    },
    showInfoWindow(job, idx) {
      if (job.info === undefined)
        return

      this.infoWindowPos = job.position
      this.infoContent = job.info
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = true
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
    hideInfoWindow() {
      this.infoWinOpen = false
    },
  },
  watch: {
    cleanerArrived(newValue, oldValue) {
      if (this.available && newValue && !oldValue) {
        this.$emit('cleaner-arrived')
      }
    },
    meetupCoords(newValue) {
      if (this.enabled && newValue) {
        this.map.panTo(newValue)
      }
    },
  }
}
</script>

<style>
.gm-style .controls {
  font-size: 28px; /* this adjusts the size of all the controls */
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  font-weight: 300;
  height: 1em;
  margin: 10px;
  text-align: center;
  user-select: none;
  padding: 3px;
  /* width: 1em; */
}

.gm-style .controls button {
  border: 0;
  background-color: white;
  color: rgba(0, 0, 0, 0.6);
}

.gm-style .controls button:hover {
  color: rgba(0, 0, 0, 0.9);
}

.gm-style .controls.destinations-control {
  display: flex;
  flex-direction: row;
  width: auto;
}

.gm-style .controls.destinations-control button {
  display: inline-block;
  font-size: 0.5em;
  margin: 0 1px;
  padding: 0 6px;
}

.gm-style .controls.destinations-control span {
  display: inline-block;
  font-size: 0.5em;
  margin: 0 1px;
  padding: 3px 6px;
}


.gm-style .controls.destinations-control.destinations-control-is-client .destinations-control-client {
  font-weight: 700;
}

.gm-style .controls.destinations-control.destinations-control-is-property .destinations-control-property {
  font-weight: 700;
}

.gm-style .controls.destinations-control.destinations-control-is-meetup .destinations-control-meetup {
  font-weight: 700;
}

.gm-style .controls.drive-control {
  display: flex;
  flex-direction: column;
  height: auto;
}

.gm-style .controls.drive-control button {
  font: 0.85em Arial;
  margin: 1px;
  padding: 0;
}


</style>

