<script>
import Extras from '@/shared/updateable_extras.vue'

export default {
  name: 'UpdateableVisitExtras',
  extends: Extras,
  props: {
    context: {
      type: String,
      default: 'visit-extras'
    },
    wrapperClass: {
      type: String,
      default: null
    }
  }
}
</script>
