<template>
  <div v-if="hasReimbursement" v-bind="$attrs">
    <div class="float-left">
      {{ description }} (Paid by client)
    </div>
    <div class="float-right blue-text">{{ cents | toCurrency }}</div>
  </div>
</template>
<script>

export default {
  props: {
    reimbursement: {
      type: Number|String,
      default: 0
    },
    description: {
      type: String
    }
  },
  computed: {
    cents() {
      return this.reimbursement * 100
    },
    hasReimbursement() {
      return (this.reimbursement && this.reimbursement != 0)
    }
  }
}
</script>
