<script>
import Dropdown from '../inputs/select.vue'

export default {
  name: 'bedrooms_dropdown',
  extends: Dropdown,
  props: {
    options: {
      type: Array,
      default () {
        return Window.bedroomOptions
      }
    },
    rules: {
      type: String,
      default: 'required'
    },
    label: {
      type: String,
      default: 'Bedrooms'
    },
    name: {
      type: String,
      default: 'bedrooms'
    }
  }
}
</script>
