<template>
  <span v-if="show" class="text-info"> (custom price)</span>
</template>
<script>

export default {
  props: {
    event: {
      type: Object,
      required: true
    },
  },
  computed: {
    show() {
      return (this.isStaff || this.isClient) && this.hasCustomPrice
    },
    hasCustomPrice() {
      return !!this.event.custom_prices
    }
  }
}
</script>
