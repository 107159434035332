<template>
  <div class="col-12">
    <div v-if="loading" class="text-center"><spinner message="" /></div>
    <div v-else class="timeline-info-container mt-3 mb-4 px-4 d-flex">
      <div class="container">
        <h4 class="text-danger text-center">{{ title }}</h4>
        <p class="text-center"><small>The options for the rule that best matches the situation is automatically selected.</small></p>
        <div class="row justify-content-center">
          <div class="col-sm-8">
            <round-dropdown v-if="triggerOptions.length > 0" v-model="trigger" :options="triggerOptions" cssClass="" />
          </div>
        </div>
        <div class="text-info text-center">{{ details }}</div>
        <my-form :report-success="false" :start-in-edit-mode="true" :force-edit-mode="true">
          <template v-slot:default="{invalid, valid, errors }">
            <div class="row justify-content-center align-items-center">
              <h5 class="col-sm-12 text-center my-4">Please tell us why you want to cancel this job</h5>
              <div class="col-sm-12">
                <p class="text-center cursor-pointer" v-b-toggle.sidebar-right>Reasons Index <i class="far fa-info-circle"></i></p>
                <b-sidebar id="sidebar-right" title="Reasons Index" right shadow>
                  <div class="px-3 py-2">
                    <hr />
                    <h2 class="text-left">Poor Cleaning Quality:</h2>
                    <ul class="text-left">
                      <li>Inadequate Cleaning: Subpar cleanliness.</li>
                      <li>Inconsistency: Varied performance.</li>
                    </ul>
                    <h2 class="text-left">Communication Issues:</h2>
                    <ul class="text-left">
                      <li>Delayed Responses: Sluggish communication.</li>
                      <li>Resolution Challenges: Difficulty in addressing concerns.</li>
                    </ul>
                    <h2 class="text-left">Unreliable Service:</h2>
                    <ul class="text-left">
                      <li>Staff Changes: Unpredictable personnel alterations.</li>
                      <li>Missed Appointments: Failure to meet agreed times.</li>
                      <li>Unwanted Schedule Changes: Inconvenient alterations.</li>
                      <li>Last-Minute Cancellations: Abrupt service terminations.</li>
                    </ul>
                    <h2 class="text-left">Budget Constraints:</h2>
                    <ul class="text-left">
                      <li>Cost Increases: Unexpected service charge rises.</li>
                      <li>Financial Limits: Scope limitations.</li>
                    </ul>
                    <h2 class="text-left">Lack of Flexibility:</h2>
                    <ul class="text-left">
                      <li>Rigid Scheduling: Limited adaptability.</li>
                      <li>Inability to Accommodate: Difficulty meeting diverse needs.</li>
                      <li>Too Many Reschedules: Frequent adjustments causing inconvenience.</li>
                    </ul>
                    <h2 class="text-left">Unmet Expectations:</h2>
                    <ul class="text-left">
                      <li>Unrealistic Promises: Commitments beyond capability.</li>
                      <li>Failure to Adapt: Inability to evolve.</li>
                    </ul>
                    <h2 class="text-left">Personal Circumstances:</h2>
                    <ul class="text-left">
                      <li>Relocation Issues: Moving to inaccessible areas.</li>
                      <li>Living Changes: Shifts impacting service needs.</li>
                    </ul>
                    <h2 class="text-left">Competitive Challenges:</h2>
                    <ul class="text-left">
                      <li>Better Offers: Superior deals from rivals.</li>
                      <li>Negative Reputation: Adverse word-of-mouth.</li>
                    </ul>
                    <h2 class="text-left">Client Absence:</h2>
                    <ul class="text-left">
                      <li>Inability to access premises due to client absence.</li>
                    </ul>
                    <h2 class="text-left">Schedule Changes:</h2>
                    <ul class="text-left">
                      <li>Alterations in agreed service times.</li>
                    </ul>
                    <h2 class="text-left">Rejected Cleaning:</h2>
                    <ul class="text-left">
                      <li>Customer dissatisfaction leading to refusal.</li>
                    </ul>
                  </div>
                </b-sidebar>
              </div>
              <div class="col-sm-6">
                <dropdown v-model="main_reason" :options="reasons" css-class="" />
              </div>
            </div>
            <div class="row mt-2 d-flex justify-content-center align-items-center">
              <div class="col-sm-10 form-group">
                <b-form-group
                  label="Would you like to charge cancellation fee?"
                  label-cols-sm="7"
                  content-cols-sm="3"
                >
                  <label class="checkbox-set" id="charge_yes">Yes
                    <input type="radio" :value="true" v-model="chargeFee" />
                    <span class="checkmark checkmark-blue"></span>
                  </label>
                  <label class="checkbox-set" id="charge_no">No
                    <input type="radio" :value="false" v-model="chargeFee" />
                    <span class="checkmark checkmark-blue"></span>
                  </label>
                </b-form-group>
              </div>
            </div>

            <div v-if="chargeFee" class="row d-flex justify-content-center align-items-center">
              <div class="col-sm-5">
                <b-form-group
                  label="Amount to charge ($)"
                  label-cols-sm="8"
                  content-cols-sm="4"
                >
                  <amount-field v-model="fee" :hide-label="true" rules="" :format-as-currency="true" type="text" />
                </b-form-group>
              </div>
              <div class="col-sm-5">
                <b-form-group
                  v-if="hasJob"
                  label="Pay cleaner?"
                  label-cols-sm="5"
                  content-cols-sm="7"
                >
                  <label class="checkbox-set" id="pay_cleaner">Yes
                    <input type="radio" :value="true" v-model="payCleaner" />
                    <span class="checkmark checkmark-blue"></span>
                  </label>
                  <label class="checkbox-set" id="do_not_pay_cleaner">No
                    <input type="radio" :value="false" v-model="payCleaner" />
                    <span class="checkmark checkmark-blue"></span>
                  </label>
                </b-form-group>
              </div>
            </div>

            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-sm-10 form-group">
                <b-form-group
                  label="What to cancel?"
                  label-cols-sm="12"
                  content-cols-sm="auto"
                >
                  <label class="checkbox-set" id="range_this">This visit
                    <input type="radio" value="this" v-model="range" />
                    <span class="checkmark checkmark-blue"></span>
                  </label>
                  <label v-if="isRecurring" class="checkbox-set" id="range_following">This and the following visits
                    <input type="radio" value="from_this" v-model="range" />
                    <span class="checkmark checkmark-blue"></span>
                  </label>
                  <label v-if="isRecurring && isGlobalAdmin" class="checkbox-set" id="range_all">All visits
                    <input type="radio" value="all" v-model="range" />
                    <span class="checkmark checkmark-blue"></span>
                  </label>
                </b-form-group>
                <small v-if="isRecurring">Note: Any possible fee only applies to this visit</small>
              </div>

              <div v-if="isAdmin" class="col-sm-10 form-group mb-5 text-center">
                <label class="checkbox-set" id="no-email">Skip notifications
                  <input type="checkbox" :value="true" v-model="noEmail" />
                  <span class="checkmark checkmark-blue"></span>
                </label>
              </div>
            </div>
          </template>
          <template v-slot:edit-buttons="{ invalid, validated }">
            <div class="form-group">
              <div class="btn-set mb-0 mt-3">
                <b-button :disabled="invalid && validated" type="submit" class="btn btn-mini btn-red main-btn m-2">Cancel</b-button>
                <b-button @click="exitCancel" class="btn btn-mini btn-blue main-btn m-2">Do not Cancel</b-button>
              </div>
            </div>
          </template>
        </my-form>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import sharedApi from '@/shared/api'
import AmountField from '@/shared/fields/amount.vue'
import MyText from '@/shared/inputs/text.vue'
import Dropdown from '@/shared/inputs/base_select.vue'
import RoundDropdown from '@/shared/fields/round_dropdown.vue'
import MyForm from '@/shared/base_form.vue'
import Spinner from '@/shared/inline_spinner.vue'

export default {
  props: {
    event: {
      type: Object,
      required: true
    },
    userType: {
      type: String,
      required: true
    }
  },
  components: { MyForm, Dropdown, MyText, AmountField, RoundDropdown, Spinner },
  data() {
    return {
      cancelling: false,
      loading: false,
      details: null,
      //main_reason: 'Client rejected the cleaning',
      main_reason: '',
      reasons: [
        { label: 'Client was not home', value: 'Client was not home' },
        { label: 'Client rejected the cleaning', value: 'Client rejected the cleaning' },
        { label: 'Poor quality of cleaning', value: 'Poor quality of cleaning' },
        { label: 'Communication problems', value: 'Communication problems' },
        { label: 'Unreliable or inconsistent service', value: 'Unreliable or inconsistent service' },
        { label: 'Budget constraints', value: 'Budget constraints' },
        { label: 'Lack of flexibility', value: 'Lack of flexibility' },
        { label: 'Unmet expectations', value: 'Unmet expectations' },
        { label: 'Personal circumstances', value: 'Personal circumstances' },
        { label: 'Competitive alternatives', value: 'Competitive alternatives' },
        { label: 'Change of schedule', value: 'Change of schedule' },
        //{ label: 'Other', value: '' }
      ],
      chargeFee: false,
      feeCents: 0,
      payCleaner: true,
      noEmail: false,
      range: 'this',
      trigger: null,
      triggers: [],
    }
  },
  computed: {
    fee: {
      get() {
        return Math.round(this.feeCents / 100)
      },
      set(v) {
        this.feeCents = Math.round(v * 100)
      }
    },
    hasJob() {
      return this.event != null && this.event.assigned
    },
    isRecurring() {
      return this.event != null && this.event.schedule != null
    },
    params() {
      return {
        for: this.userType,
        range: this.range,
        reason: this.main_reason,
        no_email: this.noEmail,
        charge_fee: this.chargeFee,
        fee_cents: this.feeCents,
        pay_cleaner: this.chargeFee && this.payCleaner
      }
    },
    reservationId() {
      return this.event && this.event.id
    },
    title() {
      if (this.trigger && this.triggerOptions.length > 0) {
        return this.triggerOptions.find(obj => obj.value === this.trigger).label
      }
    },
    triggerOptions() {
      let options = []
      for (let i=0; i < this.triggers.length; i++) {
        let v = this.triggers[i][0]
        const l = this.triggers[i][1] + ' Cancelation'
        options.push({ value: v, label: l })
      }
      return options
    }
  },
  mounted() {
    this.requestCancel()
  },
  methods: {
    doCancel() {
      this.$emit('update:loading', true)
      sharedApi.cancelVisit(this.reservationId, this.params).then(response => {
        this.$emit('reservation-updated')
        this.$flasher.success(response.data.msg)
      }, (error) => {
        this.$emit('update:loading', false)
        this.$flasher.errors(error.response.data.errors)
      })
    },
    exitCancel() {
      this.cancelling = false
      this.$emit('update:action', null)
    },
    requestCancel() {
      this.cancelling = true
      this.loading = true
      sharedApi.requestVisitCancel(this.reservationId, this.userType, this.trigger).then(response => {
        this.loading = false
        this.details = response.data.details
        if (this.trigger == null) {
          this.trigger = response.data.trigger
        }
        this.triggers = response.data.triggers
        this.feeCents = 0
        if (response.data.fee_cents) {
          this.feeCents = response.data.fee_cents
        }
        this.chargeFee = response.data.charge_fee
      }, errors => {
        this.loading = false
        this.$flasher.errors(errors.response.data.errors)
      })
    },
    submit() {
      if (this.trigger == null) {
        return new Promise((resolve, reject) => {
          reject({ errors: [ "You need to choose a rule" ] })
        })
      }

      if (this.main_reason == '') {
        return new Promise((resolve, reject) => {
          reject({ errors: [ "You need to choose a reason" ] })
        })
      }

      if (this.range == 'this') {
        return new Promise((resolve, reject) => {
          this.$dialog.confirm('Are you sure you want to cancel this visit?', {
            okText: 'Yes',
            cancelText: 'No'
          }).then((dialog) => {
            this.doCancel()
            resolve()
          }).catch(() => { reject(false) })
        })
      }
      if (this.range == 'from_this') {
        return new Promise((resolve, reject) => {
          this.$dialog.confirm('Are you sure you want to cancel this and future visits?', {
            okText: 'Yes',
            cancelText: 'No'
          }).then((dialog) => {
            this.doCancel()
            resolve()
          }).catch(() => { reject(false) })
        })
      }
      if (this.range == 'all') {
        return new Promise((resolve, reject) => {
          this.$dialog.confirm('Are you sure you want to cancel the whole schedule?', {
            okText: 'Yes',
            cancelText: 'No'
          }).then((dialog) => {
            this.doCancel()
            resolve()
          }).catch(() => { reject(false) })
        })
      }

      return new Promise((resolve, reject) => {
        reject('BUG?')
      })
    },
  },
  watch: {
    readonly(new_value) {
      if (new_value === true) {
        this.exitCancel()
      }
    },
    trigger() {
      this.requestCancel()
    }
  }

}
</script>
