<template>
   <div v-if="cancellationDetails" class="float-parent font-mid-bold clearfix mt-3 mb-2">
      <div class="float-left">{{ cancellationInfoForBreakdown }}</div>
      <div class="float-right blue-text">$25.00</div>
    </div>
  </div>
</template>
<script>
import cancellation_info_mixin from '@/shared/cancellation_info_mixin'
export default {
  mixins: [ cancellation_info_mixin ],
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    cancellationDetails() {
      if (Object.keys(this.event.cancellation_details).length != 0) {
        return this.event.cancellation_details
      }
      return null
    },
  }
}
</script>
